@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-sans text-black leading-normal tracking-normal;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    @apply tracking-tight leading-tight; /* Custom spacing for headings */
}

/* Paragraphs */
p {
    @apply leading-relaxed; /* Relaxed line height for paragraphs */
}

/* Buttons */
.button-primary {
    @apply bg-[#0171BB] text-white hover:bg-[#015A9A]; /* Primary color with hover effect */
}

.button-secondary {
    @apply bg-secondary text-white hover:bg-secondary-dark; /* Secondary color with hover effect */
}


@layer components {
    ul::before {
      content: '';
      position: absolute;
      top: 0;
      left: -2px; /* Adjust as needed to align with vertical border */
      width: calc(100% + 2px); /* Adjust to ensure it stretches across */
      border-top: 2px solid #d1d5db; /* Adjust color to match the vertical border */
      z-index: -1;
    }
  }
