.MuiAvatar-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem; /* Adjust font size as needed */
    background-color: #f0f0f0; /* Optional: Add background color to see text better */
    color: #000; /* Optional: Add text color */
  }
  
  
  .header {
    gap:10px;
    max-width: 100%!important;
  }
  .title {
    flex: 0 auto; /* Prevent title from growing */
    white-space: wrap; /* Prevent title from wrapping */
    max-width: 100%; /* Set a max width for the title to limit its area */
  }
  
  .search-bar {
    flex-grow: 1 1 auto; /* Allow the search bar to take available space */
    text-align: center; /* Center align the search bar */
  }
  
  .notifications-icon {
    margin-right: 16px; /* Margin for spacing before avatar */
  }
  

  
