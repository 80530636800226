.search-bar {
    position: relative;
    width: 60%;
}

.loading-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.search-results .MuiListItem-root {
    display: flex;
    align-items: center;
    padding: 10px;
}

.search-results .MuiListItem-root:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.search-results .MuiTypography-body2 {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.recent-searches {
    margin-top: 10px; /* Ensures there's space between the recent searches and search input */
    padding: 10px;
    background-color: white; /* Optional: Background color for recent searches section */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Light shadow */
    position: absolute;
    top: 100%;  /* Place it below the search bar */
    left: 0;
    width: 100%;
    z-index: 5; /* Below the results but above other elements */
}

