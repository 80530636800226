/* src/components/ImageSkeletonLoader.css */
@keyframes blink {
    0% {
      background-color: #f3f3f3;
    }
    50% {
      background-color: #b0b0b0; /* Darker color for the blink effect */
    }
    100% {
      background-color: #f3f3f3;
    }
  }
  
  .blinking-loader rect {
    animation: blink 1s infinite;
  }
  