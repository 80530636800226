/* src/components/OfflineMessage/OfflineMessage.css */
.offline-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .offline-message h2 {
    font-size: 2rem;
  }
  
  .offline-message p {
    font-size: 1.25rem;
  }
  
  .offline-message button {
    background-color: #721c24;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .offline-message button:hover {
    background-color: #f5c6cb;
  }
  