/* src/components/OnlineMessage/OnlineMessage.css */
.online-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #d4edda;
    color: #155724;
  }
  
  .online-message h2 {
    font-size: 2rem;
  }
  
  .online-message p {
    font-size: 1.25rem;
  }
  
  .online-message button {
    background-color: #155724;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .online-message button:hover {
    background-color: #c3e6cb;
  }
  